// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getBranchesOptions } from '@vezeeta/enterprise-utils/lib/ComboBox';
import { getBranches, setCurrentBranchKey } from '@vezeeta/enterprise-store/lib/actions/entities';
import {
  setUserDefaultLangauge,
  toggleUserDefaultLangaugeModal,
} from '@vezeeta/enterprise-store/lib/actions/user';

import styled from 'styled-components';
import { Flex, Box, Card, Tabs, Select, Button, COLORS } from '@vezeeta/web-components';

import _ from 'lodash';
import { setSelectedOffer, resetOffer } from '@vezeeta/enterprise-store/lib/actions/services';
import OffersList from './OffersList';

type Props = {
  getBranches: Function,
  branches: Map<string, Object>,
  branchesList: Map<string, Object>,
  entityKey: String,
  setCurrentBranchKey: Function,
  setSelectedOffer: Function,
  selectedOffer?: {},
  resetOffer: Function,
  countryId: Number,
  user?: {},
  isUserLoaded: Boolean,
  countries: Array,
  history: {
    push: Function,
  },
  isOfferAdded: Boolean,
  isOfferEdited: Boolean,
  language: String,
  localization: Object,
  showSwitchLanguageModal: Boolean,
  setUserDefaultLangauge: Function,
  toggleUserDefaultLangaugeModal: Function,
};

type State = {
  branchesDetails: Map<string, Branch>,
  filteredBranches: Map<string, Object>,
  prevBranches: Array<Object>,
  branchKey: String,
  shouldRefresh: Boolean,
};

const FullWidthButton = styled.div`
  min-width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  button {
    height: 50px;
    min-width: 100%;

    span {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;

const TAB_INDEX = new Map([
  ['/offers', 0],
  ['/bookings', 1],
]);

class OffersListContainer extends Component<Props, State> {
  state = {
    selectedTabValue: '',
    shouldRefresh: false,
  };

  static getDerivedStateFromProps(props) {
    return {
      selectedTabValue: props.history.location.pathname,
    };
  }

  componentDidMount = () => {
    this.loadBranchesStatusIds();
  };

  componentDidUpdate(prevProps: Props) {
    const { isOfferAdded, isOfferEdited } = this.props;
    const { shouldRefresh } = this.state;

    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.loadBranchesStatusIds();
    }

    if ((!prevProps.isOfferAdded && isOfferAdded) || (!prevProps.isOfferEdited && isOfferEdited)) {
      this.setState({ shouldRefresh: true });
    } else if (shouldRefresh) {
      this.setState({ shouldRefresh: false });
    }
  }

  setBranchKey = branchKey => {
    this.setState({
      branchKey,
    });
    this.props.setCurrentBranchKey(branchKey);
  };

  handleOfferSelect = _.memoize(offer => () => {
    this.props.setSelectedOffer(offer);
  });

  handleTabChange = selectedTabValue => {
    this.setState({
      selectedTabValue,
    });
    this.props.resetOffer();
    this.props.history.push(selectedTabValue);
  };

  /**
   * Get branches statuses
   */
  loadBranchesStatusIds = () => {
    const { branches, branchesList } = this.props;

    if (branches.size !== 0) {
      // If the branches from profile API size is the
      // same as branches from accounts API we don't need to
      // load anything
      if (branches.size !== branchesList.size) {
        const branchesKeys = [...branches.keys()];
        const notLoadedBranches = [];

        // Loop through branches from accounts and see if it's exists
        // in entities store
        branchesKeys.forEach(branch => {
          const isBranchLoaded = branchesList.get(branch);

          if (!isBranchLoaded) {
            notLoadedBranches.push(branch);
          }
        });

        // If all branches are loaded we don't call getBranches at all
        if (notLoadedBranches.length !== 0) {
          this.props.getBranches(notLoadedBranches, true);
        }
      }
    }
  };

  render() {
    const { branchKey, shouldRefresh } = this.state;
    const {
      branches,
      entityKey,
      countryId,
      countries,
      selectedOffer,
      user,
      isUserLoaded,
      language,
      localization,
      showSwitchLanguageModal,
      setUserDefaultLangauge,
      toggleUserDefaultLangaugeModal,
    } = this.props;
    const TAB_ACTION = new Map([
      [
        '/offers',
        ({ action }: { action: Number }) => (
          <FullWidthButton>
            <Button width={1} primary color={COLORS.PRIMARY_BLUE} onClick={action}>
              {localization.addOfferButton}
            </Button>
          </FullWidthButton>
        ),
      ],
      ['/bookings', () => {}],
    ]);
    let currency;
    if (countries && isUserLoaded && countries.length > 0) {
      const currentCountry = countries.find(country => country.CountryId === user.CountryId);
      currency = currentCountry.CountryCourrencies.CurrencyModels.find(
        modal => modal.IsDefault,
      ).LanguageItemModels.find(item => item.LanguageId === (language === 'en' ? 1 : 2)).Name;
    }

    const tabs = [
      {
        name: localization.offerTab.name,
        value: '/offers',
        component: (
          <OffersList
            branches={branches}
            branchKey={branchKey}
            entityKey={entityKey}
            currency={currency}
            countryId={countryId}
            onOfferSelect={this.handleOfferSelect}
            selectedOffer={selectedOffer}
            shouldRefresh={shouldRefresh}
            language={language}
            localization={localization.offerTab}
            user={user}
            showSwitchLanguageModal={showSwitchLanguageModal}
            toggleUserDefaultLangaugeModal={toggleUserDefaultLangaugeModal}
            setUserDefaultLangauge={setUserDefaultLangauge}
          />
        ),
      },
      // {
      //   name: 'Bookings',
      //   value: '/bookings',
      // },
    ];

    return (
      <Flex flexDirection="column" justifyContent="space-between" alignItems="center">
        <Box width={1} mb={2}>
          <Card px={1} mb={2}>
            <Select
              width={1}
              placeholder={localization.branch}
              disableValidation
              noIcon
              items={getBranchesOptions(
                Array.from(branches.values()).filter(branch => branch.BookingTypeId !== 3),
              )}
              onChange={this.setBranchKey}
              noBottomBorder
              selectFirst
              reverse={language !== 'en'}
              language={language}
              emptyStateMessage={localization.noOptions}
            />
          </Card>
        </Box>
        <Box width={1} mb={2}>
          <Card>
            <Flex width={1} mb={2}>
              <Tabs
                // YScrollContent
                tabs={tabs}
                select={TAB_INDEX.get('/offers')}
                onChange={this.handleTabChange}
              />
            </Flex>
            {/* Add Offer btn  */}
            <Flex width={1} justifyContent="center" alignItems="center" bg={COLORS.PRIMARY_BLUE}>
              {TAB_ACTION.get('/offers')({
                action: this.props.resetOffer,
              })}
            </Flex>
          </Card>
        </Box>
      </Flex>
    );
  }
}

const mapStateToProps = state => ({
  branches: state.clinics.branchesMap,
  user: state.user.user,
  isUserLoaded: state.user.loaded,
  branchesList: state.entities.branchesList,
  countries: state.country.Countries,
  selectedOffer: state.services.selectedOffer,
  isOfferAdded: state.services.isOfferAdded,
  isOfferEdited: state.services.isOfferEdited,
  lastAddedOffer: state.services.lastAddedOffer,
  showSwitchLanguageModal: state.user.userLangiageModal.isOpen,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBranches,
      setCurrentBranchKey,
      setSelectedOffer,
      resetOffer,
      toggleUserDefaultLangaugeModal,
      setUserDefaultLangauge,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OffersListContainer));
