// @flow

import React from 'react';
import styled from 'styled-components';
import { Flex, Card, COLORS, COLORS_VALUES } from '@vezeeta/web-components';
import { toIndiaDigits } from '@vezeeta/enterprise-utils/lib/Helpers';

const OfferCard = styled(Card)`
  margin-top: 12px;
  padding: 12px;
  cursor: pointer;
  border: 1px solid white;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  -moz-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);

border-color: ${props =>
    props.bundleStatus === 'Expired' ? '#EC1C24 !important' : COLORS_VALUES[COLORS.WHITE]};

border-color: ${props =>
    props.isSelected ? COLORS_VALUES[COLORS.PRIMARY_BLUE] : COLORS_VALUES[COLORS.WHITE]};
  /* transform: ${props => (props.isSelected ? 'translateY(-2%)' : '')}; */
  transition: all 0.3s ease-in-out;
  margin-bottom:2px;

`;

const CardTitle = styled.div`
  color: #0070cd;
  font-size: 16px;
  font-weight: bold;
`;

const CardDesc = styled.div`
  color: #808184;
  font-size: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
  white-space: nowrap;
`;

const Price = styled.div`
  font-size: 15px;
  color: #0070cd;
  font-weight: 500;
  margin-top: 5px;
  text-align: right;
`;

const Status = styled.div`
  font-size: 14px;
  padding-left: 3px;
  color: #0070cd;
  font-weight: 500;
  /* margin-top: 10px; */
  color: ${({ bundleStatus, localization }) =>
    (bundleStatus === localization.underReview && '#0070CD') ||
    (bundleStatus === localization.approved && '#15A700') ||
    (bundleStatus === localization.rejected && '#000000') ||
    (bundleStatus === 'Hidden' && '#808184') ||
    (bundleStatus === 'Freezed' && 'yellow') ||
    (bundleStatus === localization.expired && '#EC1C24')};
`;

const DateFall = styled.div`
  padding-left: 3px;
  font-size: 14px;
  /* font-weight:bold; */
  color: #808184;
`;

type Props = {
  bundleName: String,
  offerName: String,
  offerPrice: Number,
  onSelect: Function,
  offerUnitCount: Number,
  offerUnitName: String,
  bundleStatus: Number,
  currency: String,
  isSelected: Boolean,
  language: String,
  localization: Object,
};

const localizeOfferItem = (item, language) => {
  if (language !== 'en') {
    return toIndiaDigits(item);
  }
  return item;
};

function BundelCard(props: Props) {
  const {
    bundleName,
    offerName,
    offerPrice,
    onSelect,
    offerUnitCount,
    offerUnitName,
    bundleStatus,
    currency,
    isSelected,
    dateStart,
    dateEnd,
    language,
    localization,
  } = props;

  const BUNDLE_STATUS = {
    '0': localization.status.underReview,
    '1': localization.status.approved,
    '2': localization.status.rejected,
    '3': 'Hidden',
    '4': 'Freezed',
    '-300': localization.status.expired,
  };

  const formattedStartDate = new Date(dateStart).toLocaleDateString(`${language}-EG`, {
    month: 'short',
    day: 'numeric',
  });

  const formattedEndDate = new Date(dateEnd).toLocaleDateString(`${language}-EG`, {
    month: 'short',
    day: 'numeric',
  });

  return (
    <OfferCard
      onClick={onSelect}
      isSelected={isSelected}
      bundleStatus={BUNDLE_STATUS[bundleStatus]}
    >
      <CardTitle>{localizeOfferItem(bundleName, language)}</CardTitle>
      <CardDesc>{`${localizeOfferItem(
        offerUnitCount.toString(),
        language,
      )} ${offerUnitName} ${localizeOfferItem(offerName, language)}`}</CardDesc>
      <DateFall>{`${localization.bundle.from}: ${formattedStartDate} ${
        localization.bundle.to
      }: ${formattedEndDate}`}</DateFall>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <Status bundleStatus={BUNDLE_STATUS[bundleStatus]} localization={localization.status}>
          {BUNDLE_STATUS[bundleStatus]}
        </Status>
        <Price>{`${localizeOfferItem(offerPrice.toString(), language)}  ${currency}`}</Price>
      </Flex>
    </OfferCard>
  );
}

export default BundelCard;
