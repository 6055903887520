import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEntities } from '@vezeeta/enterprise-store/lib/actions/entities';
import PropTypes from 'prop-types';
import { Spinner, Flex, Box } from '@vezeeta/web-components';

import OffersListContainer from './offersList/OffersListContainer';

class OffersContainer extends Component {
  componentDidMount = () => {
    this.getEntities();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isUserLoaded !== this.props.isUserLoaded) {
      this.getEntities();
    }
  }

  getEntities = () => {
    const { user, isUserLoaded, entitiesList } = this.props;
    if (isUserLoaded) {
      if (entitiesList.size === 0) {
        this.props.getEntities([user.EntityKey]);
      }
    }
  };

  render() {
    const { isUserLoaded, user, language, localization } = this.props;

    const content = isUserLoaded ? (
      <Flex flexDirection="row" justifyContent="center" alignItems="center">
        <Box width={1}>
          <OffersListContainer
            language={language}
            localization={localization}
            entityKey={user.EntityKey}
            countryId={user.CountryId}
          />
        </Box>
      </Flex>
    ) : (
      <Flex justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );
    return content;
  }
}

OffersContainer.propTypes = {
  user: PropTypes.object,
  isUserLoaded: PropTypes.bool,
  entitiesList: PropTypes.shape([]),
  getEntities: PropTypes.func,
  language: PropTypes.string,
  localization: PropTypes.object,
};

const mapStateToProps = state => ({
  isUserLoaded: state.user.loaded,
  user: state.user.user,
  entitiesList: state.entities.entitiesList,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getEntities,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OffersContainer);
