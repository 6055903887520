// @flow

import React, { Component } from 'react';
import { Flex, Box, Card, Spinner, EmptyState, Select, Modal } from '@vezeeta/web-components';
import { API, Cookie, decodeJwtToken } from '@vezeeta/web-utils';
import styled from 'styled-components';
import SwitchLanguageModal from '@vezeeta/enterprise-modals/lib/switchLanguage/SwitchLanguageModal';

import BundelCard from '../../bundelCard/BundelCard';

const RTLModal = styled(Modal)`
  direction: rtl;
  margin: 25vh auto !important;
`;

const ServicesScrollLoaderContainer = styled(Card)`
  width: 100%;
  max-width: 440px;
  overflow-y: auto;
  margin: auto;
  /* padding: 0 20px 0 10px; */
  height: 54vh;
  padding-left: 5px;
  padding-right: 5px;

  &::-webkit-scrollbar {
    display: unset !important;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #b9b8b8;
  }

  ${props => props.theme.media.phone`
    max-width: unset;
  `};

  ${props => props.theme.media.tablet`
    max-width: unset;
  `};
`;

const EmptyStateContainer = styled.div`
  padding-top: 65px;
`;

type Props = {
  branches: {},
  branchKey: String,
  entityKey: String,
  pageSize: Number,
  onOfferSelect: Function,
  selectedOffer?: {},
  countryId: Number,
  currency: String,
  shouldRefresh: Boolean,
  language: String,
  localization: Object,
  user: Object,
  showSwitchLanguageModal: Boolean,
  setUserDefaultLangauge: Function,
  toggleUserDefaultLangaugeModal: Function,
};

type State = {
  defaultPageSize: Number,
  pageIndex: Number,
  offers: Array<Object>,
  accountKey: String,
  branchKey: String,
  status: String,
  value: Number,
  time: Date,
  thereIsAnExpiredItem: Boolean,
};

class OffersList extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      defaultPageSize: 5,
      pageIndex: 1,
      offers: [],
      branchKey: this.props.branchKey,
      isItemsAvailable: true,
      loading: true,
      status: '-1',
      value: -1,
      time: Date.now(),
      thereIsAnExpiredItem: false,
    };
  }

  componentDidMount() {
    const accountKey = decodeJwtToken(Cookie.get(Cookie.AUTH_TOKEN)).payLoad.unique_name;
    this.setState({ loading: true });
    this.getOffers(accountKey);
    this.interval = setInterval(
      () => this.setState({ time: Date.now() }, () => this.onStatusChange2()),
      3600000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.branchKey !== this.props.branchKey ||
      prevProps.entityKey !== this.props.entityKey
    ) {
      this.setState(
        {
          offers: [],
          pageIndex: 1,
          loading: true,
        },
        () => {
          this.getOffers();
        },
      );
    }

    if (this.props.shouldRefresh) {
      this.setState(
        {
          offers: [],
          pageIndex: 1,
          loading: true,
        },
        () => {
          this.getOffers();
        },
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { branchKey } = props;

    if (branchKey !== state.branchKey) {
      return { branchKey, offers: [] };
    }
    return null;
  }

  onScrollOffers = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && this.state.isItemsAvailable) {
      this.setState({ loading: true });
      this.getOffers();
    }
  };

  isBottom = el => el.getBoundingClientRect().bottom <= window.innerHeight;

  onStatusChange = e => {
    this.setState(
      {
        offers: [],
        pageIndex: 1,
        loading: true,
        status: e,
        value: e,
      },
      () => {
        this.getOffers();
      },
    );
  };

  onStatusChange2 = () => {
    this.setState(
      {
        offers: [],
        pageIndex: 1,
        loading: true,
        status: this.state.status,
        value: this.state.value,
      },
      () => {
        this.getOffers();
      },
    );
  };

  getOffers = accountKey => {
    const { entityKey, pageSize, countryId, language } = this.props;
    const { branchKey } = this.state;
    const accountToken = Cookie.get(Cookie.AUTH_TOKEN);

    let account;
    if (accountKey) {
      account = accountKey;
      this.setState({
        accountKey,
      });
    } else {
      account = this.state.accountKey;
    }
    const getOffersHeaders = [
      {
        key: 'countryId',
        value: countryId,
      },
      {
        key: 'languageId',
        value: language === 'en' ? 1 : 2,
      },
      {
        key: 'x-vzt-authentication',
        value: accountToken,
      },
    ];
    this.getOffers2();
    const size = this.props && pageSize ? pageSize : this.state.defaultPageSize;
    const page = this.state.pageIndex;
    const status = this.state.status;
    if (branchKey) {
      this.setState({ isItemsAvailable: false });
      const getOffers = new API();
      getOffers
        .get(
          `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GATEWAY}/so-api/api/provider/GetProviderOffers?pageSize=${size}&pageIndex=${page}&entityKey=${entityKey}&branchKey=${branchKey}&accountKey=${account}&bundleStatus=${status}`,
          getOffersHeaders,
        )
        .then(res => {
          if (res.data !== null && res.data.length > 0) {
            this.setState(
              {
                offers: this.state.offers.concat(res.data),
                pageIndex: this.state.pageIndex + 1,
                isItemsAvailable: true,
                loading: false,
              },
              () => {
                if (this.props.showSwitchLanguageModal === undefined) {
                  this.props.toggleUserDefaultLangaugeModal(true);
                }
              },
            );
          } else {
            this.setState(
              {
                isItemsAvailable: false,
                loading: false,
              },
              () => {
                if (this.props.showSwitchLanguageModal === undefined) {
                  this.props.toggleUserDefaultLangaugeModal(true);
                }
              },
            );
          }
        });
    }
  };

  getOffers2 = accountKey => {
    const { entityKey, countryId, language } = this.props;
    const { branchKey } = this.state;
    const accountToken = Cookie.get(Cookie.AUTH_TOKEN);

    let account;
    if (accountKey) {
      account = accountKey;
      this.setState({
        accountKey,
      });
    } else {
      account = this.state.accountKey;
    }
    const getOffersHeaders = [
      {
        key: 'countryId',
        value: countryId,
      },
      {
        key: 'languageId',
        value: language === 'en' ? 1 : 2,
      },
      {
        key: 'x-vzt-authentication',
        value: accountToken,
      },
    ];
    const size = 1;
    const page = 1;
    if (branchKey) {
      const getOffers = new API();
      getOffers
        .get(
          `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GATEWAY}/so-api/api/provider/GetProviderOffers?pageSize=${size}&pageIndex=${page}&entityKey=${entityKey}&branchKey=${branchKey}&accountKey=${account}&bundleStatus=-300`,
          getOffersHeaders,
        )
        .then(res => {
          if (res.data !== null && res.data.length > 0) {
            let expiredItems = 0;
            res.data.forEach((ids, thing) => {
              if (thing.bundleStatus === -300) {
                expiredItems += 1;
              }
            });
            if (expiredItems > 0) {
              this.setState({ thereIsAnExpiredItem: true });
            } else {
              this.setState({ thereIsAnExpiredItem: false });
            }
          } else {
            this.setState({ thereIsAnExpiredItem: false });
          }
        });
    }
  };

  render() {
    const {
      onOfferSelect,
      selectedOffer,
      currency,
      language,
      localization,
      showSwitchLanguageModal,
      user,
    } = this.props;
    const { offers, loading } = this.state;
    const EMPTY_NO_OFFERS = {
      image: `${process.env.REACT_APP_CDN_URL}/assets/no_results.svg`,
      text: localization.noOffers,
    };
    const content =
      offers.length > 0 ? (
        offers.map(offer => (
          <BundelCard
            key={offer.bundleKey}
            offerData={offer}
            bundleName={offer.bundleName}
            offerName={language === 'en' ? offer.services[0].nameEN : offer.services[0].name}
            offerPrice={offer.priceAfter}
            offerUnitCount={offer.services[0].unitCount}
            offerUnitName={offer.services[0].unitName}
            bundleStatus={offer.bundleStatus}
            onSelect={onOfferSelect(offer)}
            isSelected={selectedOffer && selectedOffer.bundleKey === offer.bundleKey}
            currency={currency}
            dateStart={offer.startDate}
            dateEnd={offer.endDate}
            language={language}
            localization={localization}
          />
        ))
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <EmptyStateContainer>
            {loading ? <Spinner /> : <EmptyState imageWidth={150} {...EMPTY_NO_OFFERS} />}
          </EmptyStateContainer>
        </Flex>
      );

    /* hard coded items to be filtered by */
    let items = [
      {
        fieldValue: localization.status.none,
        children: localization.status.none,
        value: '-1',
      },
      {
        fieldValue: localization.status.underReview,
        children: localization.status.underReview,
        value: '0',
      },
      {
        fieldValue: localization.status.approved,
        children: localization.status.approved,
        value: '1',
      },
      {
        fieldValue: localization.status.rejected,
        children: localization.status.rejected,
        value: '2',
      },
      // {
      //   fieldValue:'Hidden',
      //   children :'Hidden',
      //   value:'3'
      // },
      // {
      //   fieldValue:'Freezed',
      //   children :'Freezed',
      //   value:'4'
      // },
      {
        fieldValue: localization.status.expired,
        children: localization.status.expired,
        value: '-300',
      },
    ];

    let borderValue = { color: 'white' };
    if (this.state.thereIsAnExpiredItem) {
      // borderValue = {
      //   color: 'white',
      //   border: '1px solid #EC1C24',
      //   borderRadius: '7px',
      //   paddingLeft: '5px',
      //   paddingRight: '5px',
      //   width: 'max-content',
      //   WebkitTransition: 'all',
      //   msTransition: 'all',
      //   animation: `${glow} 2000ms infinite`,
      // };
    }

    return (
      <>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ fontSize: '14px', height: '16px' }}
        >
          <Box width={1 / 2}>{localization.selectStatus}</Box>

          <Box width={1 / 2} justifyContent="end">
            <Select
              style={borderValue}
              width={1}
              // mr={4}
              placeholder="Select status"
              disableValidation
              noIcon
              items={Array.from(items.values())}
              onChange={this.onStatusChange}
              noBottomBorder
              selectFirst
              reverse={language !== 'en'}
            />
          </Box>
        </Flex>

        <ServicesScrollLoaderContainer
          onScroll={event => {
            this.onScrollOffers(event);
          }}
        >
          {!loading || offers.length ? content : ''}
          <Flex justifyContent="center" alignItems="center">
            {loading ? <Spinner /> : ''}
          </Flex>
        </ServicesScrollLoaderContainer>

        <RTLModal
          show={
            !loading &&
            showSwitchLanguageModal !== false &&
            language === 'en' &&
            user &&
            !user.DefaultLanguageId
          }
          onHide={() => {
            if (showSwitchLanguageModal !== false && showSwitchLanguageModal !== undefined) {
              this.props.toggleUserDefaultLangaugeModal(false);
              this.props.setUserDefaultLangauge(language === 'en' ? 1 : 2, false);
            }
          }}
          isArabic
          tabs={[
            {
              tabPage: <SwitchLanguageModal languageId={language === 'en' ? 1 : 2} />,
            },
          ]}
        />
      </>
    );
  }
}

export default OffersList;
